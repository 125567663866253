<template>
  <!--begin::Popup Setting-->
  <div class="popup-farm-setting">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            세팅 변경
          </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <Draggable class="setting-list row" handle=".draggable-handle" v-bind="dragOptions">
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting1></Setting1>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting2></Setting2>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting3></Setting3>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting4></Setting4>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting5></Setting5>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting6></Setting6>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting7></Setting7>
          </div>
          <div class="col-12 col-lg-6 col-xl-3">
            <Setting8></Setting8>
          </div>
        </Draggable>
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end: Popup Setting-->
</template>

<script>
import { mapGetters } from "vuex";
import Draggable from 'vuedraggable'
import Setting1 from "@/view/content/popup/Setting1.vue";
import Setting2 from "@/view/content/popup/Setting2.vue";
import Setting3 from "@/view/content/popup/Setting3.vue";
import Setting4 from "@/view/content/popup/Setting4.vue";
import Setting5 from "@/view/content/popup/Setting5.vue";
import Setting6 from "@/view/content/popup/Setting6.vue";
import Setting7 from "@/view/content/popup/Setting7.vue";
import Setting8 from "@/view/content/popup/Setting8.vue";

export default {
  name: "popup-setting",
  display: "Transitions",
  data() {
    return {
    };
  },
  components: {
    Draggable,
    Setting1,
    Setting2,
    Setting3,
    Setting4,
    Setting5,
    Setting6,
    Setting7,
    Setting8
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
  }
};
</script>
<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
