
<template>
  <div class="page-popup">
    <PopupSmartfarm></PopupSmartfarm>
    <br/>
    <PopupSetting></PopupSetting>
    <br/>
    <PopupCropsForm></PopupCropsForm>
    <br/>
    <PopupAsForm></PopupAsForm>
    <br/>
    <PopupAsView></PopupAsView>
    <br/>
    <PopupNotice></PopupNotice>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PopupSetting from "@/view/content/popup/PopupSetting.vue";
import PopupCropsForm from "@/view/content/popup/PopupCropsForm.vue";
import PopupAsForm from "@/view/content/popup/PopupAsForm.vue";
import PopupAsView from "@/view/content/popup/PopupAsView.vue";
import PopupSmartfarm from "@/view/content/popup/PopupSmartfarm.vue";
import PopupNotice from "@/view/content/popup/PopupNotice.vue";

export default {
  name: "popup",
  components: {
    PopupCropsForm,
    PopupAsForm,
    PopupAsView,
    PopupSmartfarm,
    PopupNotice,
    PopupSetting
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "팝업" }]);
  }
};
</script>
