<template>
  <!--begin::Popup Notice-->
  <div class="popup-notice">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            공지사항 상세 보기
          </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="notice-view px-lg-6 px-0 mb-4">
          <dl class="text-group row">
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">등록자</span>
            </div>
            <div class="description col-12 col-lg-5 mb-3 mb-lg-3">
                <span class="text text-truncate">관리자 (Admin)</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">등록 일시</span>
            </div>
            <div class="description col-12 col-lg-5 mb-3 mb-lg-3">
                <span class="text text-truncate">2020.01.31 00:00</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">제목</span>
            </div>
            <div class="description col-12 col-lg-11 mb-3 mb-lg-3">
                <span class="text">정기 점검이 있습니다.</span>
            </div>
            <div class="title col-12 col-lg-1 mb-lg-3">
              <span class="font-size-lg font-weight-bold">상세</span>
            </div>
            <div class="description col-12 col-lg-11 mb-3 mb-lg-3">
                <span class="text">
                  설비에 문제가 있습니다. 모든 국민은 신속한 재판을 받을 권리를 가진다. 형사피고인은 상당한 이유가 없는 한 지체없이 공개재판을 받을 권리를 가진다. 국가는 국민 모두의 생산 및 생활의 기반이 되는 국토의 효율적이고 균형있는 이용·개발과 보전을 위하여 법률이 정하는 바에 의하여 그에 관한 필요한 제한과 의무를 과할 수 있다.<br/>국정감사 및 조사에 관한 절차 기타 필요한 사항은 법률로 정한다. </span>
            </div>
          </dl>
        </div>
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="secondary" size="lg">
          <span class="">닫기</span>
        </b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end: Popup Notice-->
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "popup-notice",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
  }
};
</script>