<template>
  <!--begin::Popup Crops-->
  <div class="popup-crops-form">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            재배 작물 등록
          </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <!--begin::crops-type-->
        <div class="crops-type">
          <b-form-group label-cols="12" label-cols-lg="6" label="재배 작물 유형을 선택하세요." label-for="input-default">
            <b-form-select v-model="selected" :options="optionsA"></b-form-select>
          </b-form-group>
        </div>
        <!--end::crops-type-->
        <!--begin::crops-item-->
        <div class="crops-item">
          <dl class="text-group row mb-7">
            <dt class="title col-12 col-lg-6">
              <span class="title font-weight-bold font-size-lg">재배 작물을 선택하세요.</span>
            </dt>
            <dd class="description col-12 col-lg-6">
              <span class="text font-weight-bold text-primary">애플민트</span>
            </dd>
          </dl>
        </div>
        <!--end::crops-item-->
        <!--begin::crops-table-->
        <div class="crops-table">
          <b-table 
            responsive
            fixed 
            :items="items"
            :fields="fields"
            head-variant="light"
            class="table-vertical-center border-bottom"
          >
            <template #cell(fieldA)="data">
              <b-form-radio name="radio-size">
                {{ data.item.fieldA }}
              </b-form-radio>
            </template>
            <template #cell(fieldF)="data">
              {{ data.item.fieldF }}
              <div v-b-tooltip.hover.v-dark.right title="..." class="d-inline-block">
                  <b-icon icon="info-square-fill" aria-hidden="true" class="text-dark"></b-icon>
              </div>
            </template>
          </b-table>
        </div>
        <!--end::crops-table-->
        <!--begin::crops-bottom-->
        <div class="crops-bottom">
          <b-form-group label-cols="12" label-cols-lg="6" label="재배 작물의 월별 작물 출하량을 입력하세요." label-for="input-default">
            <b-form inline>
              <b-form-select
                class="mr-3"
                v-model="selected"
                :options="optionsB"
              >
                <template v-slot:first>
                  <option :value="null">12월</option>
                </template>
              </b-form-select>
              <b-form-input
                id="inputA"
                class="mr-3"
                type="text"
                required
                placeholder=""
                value="100"
              ></b-form-input>
              <b-form-text class="text-dark">Kg ( per ㎡ )</b-form-text>
            </b-form>
          </b-form-group>
        </div>
        <!--begin::crops-bottom-->
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="primary" size="lg">
          <span class="">등록</span>
        </b-button>
        <b-button variant="secondary" size="lg" class="ml-3">
          <span class="">닫기</span>
        </b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end: Popup Crops-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "popup-crops-form",
  data() {
    return {
      selected: null,
      optionsA: [
        { value: 'a', text: '허브류' },
        { value: 'b', text: '허브류' }
      ],
      optionsB: [
        { value: 'a', text: '1월' },
        { value: 'b', text: '2월' }
      ],
      fields: [
        { key: 'fieldA', label: '작물 이름' },
        { key: 'fieldB', label: '작물 번호' },
        { key: 'fieldC', label: '재배 작물 유형' },
        { key: 'fieldD', label: '생장기간' },
        { key: 'fieldE', label: '표준 표기 중량' },
        { key: 'fieldF', label: '재배 환경 기준' }
      ],
      items: [
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' },
        { 'fieldA' : '애플민트', 'fieldB' : '21313-1131', 'fieldC' : '허브류', 'fieldD' : '42일', 'fieldE' : '100g', 'fieldF' : 'A type' }
      ]
    };
  },
  components: {
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
  }
};
</script>