<template>
  <!--begin::Popup Smartfarm-->
  <div class="popup-smartfarm">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            스마트팜 상세
          </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body py-0">
        <!--begin::Info-->
        <div class="smartfarm-info px-lg-6 px-0 mb-4">
          <ul class="text-group row list-unstyled mb-0">
            <li class="text-item col-12">
              <div class="row">
                <div class="title col-12 col-lg-2 mb-lg-3 is-rerequired">
                  <span class="font-size-lg font-weight-bold">회사 이름</span>
                </div>
                <div class="description col-12 col-lg-10 mb-3 mb-lg-3">
                    <span class="text text-truncate">스마트팜 Corp. (1231245-123-125)</span>
                </div>
              </div>
            </li>
            <li class="text-item col-6">
              <div class="row">
                <div class="title col-12 col-lg-4 mb-lg-3">
                  <span class="font-size-lg font-weight-bold">팜 이름</span>
                </div>
                <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                    <span class="text text-truncate">보스턴 팜</span>
                </div>
              </div>
            </li>
            <li class="text-item col-6">
              <div class="row">
                <div class="title col-12 col-lg-4 mb-lg-3">
                  <span class="font-size-lg font-weight-bold">팜 코드 번호</span>
                </div>
                <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                    <span class="text text-truncate">00-00-00000-000</span>
                </div>
              </div>
            </li>
            <li class="text-item col-6">
              <div class="row">
                <div class="title col-12 col-lg-4 mb-lg-3">
                  <span class="font-size-lg font-weight-bold">가동 상태</span>
                </div>
                <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                    <span class="text text-truncate">가동중</span>
                    <span class="status-icon is-active ml-3"></span>
                </div>
              </div>
            </li>
            <li class="text-item col-6">
              <div class="row">
                <div class="title col-12 col-lg-4 mb-lg-3">
                  <span class="font-size-lg font-weight-bold">운영 방식</span>
                </div>
                <div class="description col-12 col-lg-8 mb-3 mb-lg-3">
                    <span class="text text-truncate">인도어 팜</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!--end::Info-->
        <!--begin::Tabs-->
        <div class="smartfarm-tabs">
          <b-tabs justified>
            <b-tab active>
              <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Archive.svg" />
                </span>
                <span class="nav-text font-weight-bold font-size-sm">{{i18n.basicInfoA}}</span>
              </template>
              <Smartfarm1></Smartfarm1>
            </b-tab>
            <b-tab>
              <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Archive.svg" />
                </span>
                <span class="nav-text font-weight-bold font-size-sm">{{i18n.basicInfoB}}</span>
              </template>
              <Smartfarm2></Smartfarm2>
            </b-tab>
            <b-tab>
              <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Umbrella.svg" />
                </span>
                <span class="nav-text font-weight-bold font-size-sm">{{i18n.controllerInfo}}</span>
              </template>
              <Smartfarm3></Smartfarm3>
            </b-tab>
            <b-tab>
              <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Home/Wood2.svg" />
                </span>
                <span class="nav-text font-weight-bold font-size-sm">{{i18n.cropInfo}}</span>
              </template>
              <Smartfarm4></Smartfarm4>
            </b-tab>
            <b-tab>
              <template slot="title">
                <span class="nav-icon svg-icon svg-icon-sm">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Electric/Outlet.svg" />
                </span>
                <span class="nav-text font-weight-bold font-size-sm">{{i18n.machineInfo}}</span>
              </template>
              <Smartfarm5></Smartfarm5>
            </b-tab>
          </b-tabs>
        </div>
        <!--end::Tabs-->
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="primary" size="lg">
          <span class="">등록</span>
        </b-button>
        <b-button variant="secondary" size="lg" class="ml-3">
          <span class="">닫기</span>
        </b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end: Popup Smartfarm-->
</template>

<script>
import { mapGetters } from "vuex";
import Smartfarm1 from "@/view/content/popup/Smartfarm1.vue";
import Smartfarm2 from "@/view/content/popup/Smartfarm2.vue";
import Smartfarm3 from "@/view/content/popup/Smartfarm3.vue";
import Smartfarm4 from "@/view/content/popup/Smartfarm4.vue";
import Smartfarm5 from "@/view/content/popup/Smartfarm5.vue";
import i18n from '@/core/plugins/vue-i18n.js';


export default {
  name: "popup-smartfarm",
  components: {
    Smartfarm1,
    Smartfarm2,
    Smartfarm3,
    Smartfarm4,
    Smartfarm5
  },
  data() {
    return {
      i18n: {
        basicInfoA: i18n.t('farm.basicInfoA'),
        basicInfoB: i18n.t('farm.basicInfoB'),
        controllerInfo: i18n.t('farm.controllerInfo'),
        cropInfo: i18n.t('farm.cropInfo'),
        machineInfo: i18n.t('farm.machineInfo'),
      },
      selected: null,
      info: [
        { title: '회사 이름', desc: '스마트팜 Corp.  (1231245-123-125)', required: 'is-required'},
        { title: '팜 이름', desc: '허브류', required: ''},
        { title: '보스턴 팜', desc: '00-00-00000-000', required: ''},
        { title: '가동 상태', desc: '가동중', required: '', status: 'is-active'},
        { title: '운영 방식', desc: '인도어 팜', required: ''}
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
  }
};
</script>