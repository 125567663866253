<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start">
        <span class="title font-weight-bold">
          {{ i18n.humidity }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-01" v-model="humidityValue" min="0" max="100" size="sm" @change="conveyData('humidityValue')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->

      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">관리</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>상한(주간)</div>
            <b-form-spinbutton v-model="humidityMax" min="0" max="100" size="sm" @change="conveyData('humidityMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>하한(주간)</div>
            <b-form-spinbutton v-model="humidityMin" min="0" max="100" size="sm" @change="conveyData('humidityMin')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>상한(야간)</div>
            <b-form-spinbutton v-model="humiditynMax" min="0" max="100" size="sm" @change="conveyData('humiditynMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>하한(야간)</div>
            <b-form-spinbutton v-model="humiditynMin" min="0" max="100" size="sm" @change="conveyData('humiditynMin')"></b-form-spinbutton>
          </div>
        </b-card>
      </div>           

      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">이상경보</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>상한(주간)</div>
            <b-form-spinbutton v-model="humidityLimithigh" min="0" max="100" size="sm" @change="conveyData('humidityLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>하한(주간)</div>
            <b-form-spinbutton v-model="humidityLimitlow" min="0" max="100" size="sm" @change="conveyData('humidityLimitlow')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>상한(야간)</div>
            <b-form-spinbutton v-model="humiditynLimithigh" min="0" max="100" size="sm" @change="conveyData('humiditynLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>하한(야간)</div>
            <b-form-spinbutton v-model="humiditynLimitlow" min="0" max="100" size="sm" @change="conveyData('humiditynLimitlow')"></b-form-spinbutton>
          </div>
        </b-card>
      </div> 
      <!--end::form-group-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting2",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);
    }
  },
  data() {
    return {
      humidityValue: 0,
      humidityLimithigh: 0,
      humidityLimitlow: 0,
      humidityMin: 0,
      humidityMax: 0,
      humiditynLimithigh: 0,
      humiditynLimitlow: 0,
      humiditynMin: 0,
      humiditynMax: 0,
      i18n: {
        humidity: i18n.t('cultivationEnvControlSetting.humidity'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitHigh: i18n.t('cultivationEnvControlSetting.limitHigh')
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.humidityMax || this.elementObj.currentValue < this.humidityMin) {
        return {
          color: 'red'
        }  
      }

      return {
        color: 'green'
      }
    }
  },
  methods: {
    conveyData(key){
      this.mutateValue('humidity', key, this[key]);
    }
  }
};
</script>
