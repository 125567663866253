<template>
  <!--begin::Popup AS-->
  <div class="popup-as-view">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            클레임 등록
          </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <b-form-group>
          <div class="d-flex flex-wrap">
            <!--begin::등록자-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">등록자</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">사업자 (SAUPja)</span>
              </div>
            </div>
            <!--begin::등록 일시-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">등록 일시</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">2020.01.31 00:00</span>
              </div>
            </div>
            <!--begin::회사 이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">회사 이름</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">바이오플랜트컴퍼니</span>
              </div>
            </div>
            <!--begin::팜이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">팜이름</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">알파팜</span>
              </div>
            </div>
            <!--begin::등록자 연락처-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">등록자 연락처</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">010-456-8912</span>
              </div>
            </div>
            <!--begin::등록자 이메일-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">등록자 이메일</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">kimceheol@biocompany.com</span>
              </div>
            </div>
            <!--begin::처리 단계-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">처리 단계</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">요청 (처리 대기 중)</span>
              </div>
            </div>
            <!--begin::AS요청 유형-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">AS요청 유형</span>
            </label>
            <div class="pr-14 cw-35">
              <div class="form-text">
                <span class="text">설비</span>
              </div>
            </div>
            <!--begin::처리 담당자-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-success"></span>
              <span class="text">처리 담당자</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">관리자 (admin)</span>
                </div>
              </div>
            </div>
            <!--begin::AS요청 상세-->
            <label class="d-flex bullet-label cw-15 align-items-start">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">AS요청 상세</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text">
                  <span class="text">설비에 문제가 있습니다. 모든 국민은 신속한 재판을 받을 권리를 가진다. 형사피고인은 상당한 이유가 없는 한 지체없이 공개재판을 받을 권리를 가진다. 국가는 국민 모두의 생산 및 생활의 기반이 되는 국토의 효율적이고 균형있는 이용·개발과 보전을 위하여 법률이 정하는 바에 의하여 그에 관한 필요한 제한과 의무를 과할 수 있다. 국정감사 및 조사에 관한 절차 기타 필요한 사항은 법률로 정한다.</span>
                </div>
              </div>
            </div>
            <!--begin::첨부 파일-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">첨부 파일</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <div class="form-text d-flex align-items-center">
                  <span class="text">스크린샷.zip</span>
                  <button type="button" class="btn btn-icon btn-primary btn-sm ml-4">
                    <i class="la la-download"></i>
                    </button>
                </div>
              </div>
            </div>
            <!--end::첨부 파일-->
          </div>
        </b-form-group>
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button variant="primary" size="lg">
          <span class="">접수</span>
        </b-button>
        <b-button variant="secondary" size="lg" class="ml-3">
          <span class="">닫기</span>
        </b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end: Popup AS-->
</template>

<script>

export default {
  name: "popup-as-view"
};
</script>